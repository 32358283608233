const defaultLng = 'zh';

module.exports = {
  defaultLng: defaultLng,
  config: {
    fallbackLng: defaultLng,
    lng: defaultLng,
    resources: {
      zh: {
        translation: require('../locales/zh/translation.json'),
      },
      en: {
        translation: require('../locales/en/translation.json'),
      },
    },
    languages: ['zh', 'en'],
    returnObjects: true,
    // debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    redirect: false,
  },
};
