import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  collapseCurrent: 1,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setCollapseCurrent: (state, action) => {
      const { payload } = action;
      state.collapseCurrent = payload;
    },
  },
});

// Reducers
export default productsSlice.reducer;

// Actions
const { setCollapseCurrent } = productsSlice.actions;

// Thunks
export const onCollapseClick = (index) => (dispatch) => {
  dispatch(setCollapseCurrent(index));
};
