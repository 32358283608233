import { combineReducers } from '@reduxjs/toolkit';
import common from './common';
import products from './products';
import map from '../reducers/map';

export default combineReducers({
  common,
  products,
  map,
});

