import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  activeMarkers: null,
  center: {
    lat: 22.398016,
    lng: 114.108336,
  },
  zoom: 11,
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setCenterMap: (state, action) => {
      const { payload } = action;
      state.center = payload;
    },
    setActiveMarker: (state, action) => {
      const { payload } = action;
      state.activeMarkers = payload.num;

      state.center = payload.center;
      state.zoom = 15;
    },
    removeActiveMarker: (state) => {
      state.activeMarkers = null;
    },
  },
});

// Reducers
export default mapSlice.reducer;

// Thunks
export const setCenterMap = (center) => (dispatch) => {
  dispatch(mapSlice.actions.setCenterMap(center));
};

export const setActiveMarker = (num) => (dispatch) => {
  dispatch(mapSlice.actions.setActiveMarker(num));
};

export const removeActiveMarker = (num) => (dispatch) => {
  dispatch(mapSlice.actions.removeActiveMarker(num));
};
