// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-containers-about-about-js": () => import("./../../../src/containers/About/About.js" /* webpackChunkName: "component---src-containers-about-about-js" */),
  "component---src-containers-contact-us-contact-us-js": () => import("./../../../src/containers/ContactUs/ContactUs.js" /* webpackChunkName: "component---src-containers-contact-us-contact-us-js" */),
  "component---src-containers-cookie-cookie-js": () => import("./../../../src/containers/Cookie/Cookie.js" /* webpackChunkName: "component---src-containers-cookie-cookie-js" */),
  "component---src-containers-edm-edm-202103-vip-day-p-2-hk-js": () => import("./../../../src/containers/Edm/Edm202103VIPDayP2HK.js" /* webpackChunkName: "component---src-containers-edm-edm-202103-vip-day-p-2-hk-js" */),
  "component---src-containers-edm-edm-202103-vip-day-p-2-mop-js": () => import("./../../../src/containers/Edm/Edm202103VIPDayP2MOP.js" /* webpackChunkName: "component---src-containers-edm-edm-202103-vip-day-p-2-mop-js" */),
  "component---src-containers-edm-edm-202109-ts-day-js": () => import("./../../../src/containers/Edm/Edm202109TSDay.js" /* webpackChunkName: "component---src-containers-edm-edm-202109-ts-day-js" */),
  "component---src-containers-edm-edm-202109-ts-day-vip-js": () => import("./../../../src/containers/Edm/Edm202109TSDayVIP.js" /* webpackChunkName: "component---src-containers-edm-edm-202109-ts-day-vip-js" */),
  "component---src-containers-home-home-js": () => import("./../../../src/containers/Home/Home.js" /* webpackChunkName: "component---src-containers-home-home-js" */),
  "component---src-containers-membership-membership-js": () => import("./../../../src/containers/Membership/Membership.js" /* webpackChunkName: "component---src-containers-membership-membership-js" */),
  "component---src-containers-men-home-men-home-js": () => import("./../../../src/containers/MenHome/MenHome.js" /* webpackChunkName: "component---src-containers-men-home-men-home-js" */),
  "component---src-containers-privacy-privacy-js": () => import("./../../../src/containers/Privacy/Privacy.js" /* webpackChunkName: "component---src-containers-privacy-privacy-js" */),
  "component---src-containers-recycle-recycle-js": () => import("./../../../src/containers/Recycle/Recycle.js" /* webpackChunkName: "component---src-containers-recycle-recycle-js" */),
  "component---src-containers-redeem-redeem-js": () => import("./../../../src/containers/Redeem/Redeem.js" /* webpackChunkName: "component---src-containers-redeem-redeem-js" */),
  "component---src-containers-search-search-js": () => import("./../../../src/containers/Search/Search.js" /* webpackChunkName: "component---src-containers-search-search-js" */),
  "component---src-containers-sitemap-sitemap-js": () => import("./../../../src/containers/Sitemap/Sitemap.js" /* webpackChunkName: "component---src-containers-sitemap-sitemap-js" */),
  "component---src-containers-store-locator-store-locator-js": () => import("./../../../src/containers/StoreLocator/StoreLocator.js" /* webpackChunkName: "component---src-containers-store-locator-store-locator-js" */),
  "component---src-containers-tnc-tnc-js": () => import("./../../../src/containers/Tnc/Tnc.js" /* webpackChunkName: "component---src-containers-tnc-tnc-js" */),
  "component---src-containers-top-rated-top-rated-js": () => import("./../../../src/containers/TopRated/TopRated.js" /* webpackChunkName: "component---src-containers-top-rated-top-rated-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/Product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */),
  "component---src-templates-products-index-js": () => import("./../../../src/templates/Products/index.js" /* webpackChunkName: "component---src-templates-products-index-js" */)
}

