import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import store from './src/state/store';
import { I18nextProvider } from 'react-i18next';
import I18nextContext from './src/context/I18nextContext';
import LayoutContext from './src/context/LayoutContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import './src/assets/css/font.css';
import './src/assets/scss/global.scss';
import './src/assets/scss/main.scss';

import i18next from 'i18next';
import i18nextConfig from './src/config/i18n';

export const wrapPageElement = ({ element, props }) => {
  const config = {
    ...i18nextConfig.config,
    fallbackLng: props.pageContext.locale,
    lng: props.pageContext.locale,
  };

  i18next.init(config);

  const isSSR = typeof window === 'undefined';

  const renderElement = (
    <I18nextProvider i18n={i18next}>
      <I18nextContext.Provider value={config}>
        <LayoutContext.Provider value={props}>{element}</LayoutContext.Provider>
      </I18nextContext.Provider>
    </I18nextProvider>
  );

  return !isSSR ? (
    <Suspense fallback={<div>Loading</div>}>{renderElement}</Suspense>
  ) : (
    renderElement
  );
};

export const wrapRootElement = ({ element }) => {
  // console.log("wrapRootElement", element);
  return <Provider store={store}>{element}</Provider>;
};

