import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import rootReducer from './reducers';

const middlewares = [...getDefaultMiddleware()];

if(process.env.NODE_ENV === 'development'){
  middlewares.push(logger)
}


const store = configureStore({
  reducer: rootReducer,
  middleware: middlewares,
  devTools: true,
});

export default store;
