import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  locale: 'zh',
  isMenuOpen: false,
  isMobileView: false,
  searchValue: '',
  subType: [
    {
      key: 'categories',
      name: 'slidebar_product_type',
      navigationName: 'product_type',
    },
    {
      key: 'needs',
      name: 'slidebar_needs',
      navigationName: 'skin_problems_or_needs',
    },
    { key: 'series', name: 'slidebar_franchise', navigationName: 'franchise' },
  ],
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLocale: (state, action) => {
      const { payload } = action;
      state.locale = payload;
    },
    setMenuOpen: (state, action) => {
      const { payload } = action;
      state.isMenuOpen = payload;
    },
    setIsMobileView: (state, action) => {
      const { payload } = action;
      state.isMobileView = payload;
    },
    setSearchValue: (state, action) => {
      const { payload } = action;
      state.searchValue = payload;
    },
  },
});

// Reducers
export default commonSlice.reducer;

// Actions
const {
  setLocale,
  setMenuOpen,
  setIsMobileView,
  setSearchValue,
} = commonSlice.actions;

// Thunks
export const changeLocale = (locale) => (dispatch) => {
  dispatch(setLocale(locale));
};

export const menuOnClick = (isOpen) => (dispatch) => {
  dispatch(setMenuOpen(isOpen));
};

export const changeIsMobile = (isMobile) => (dispatch) => {
  dispatch(setIsMobileView(isMobile));
};

export const onSearchChange = (isMobile) => (dispatch) => {
  dispatch(setSearchValue(isMobile));
};
